import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import MobileMenu from '../mobileMenu/mobileMenu';
import { routes } from '../../routes';
import { setEaMode, setZoom } from '../../actions/ea';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu as AntdMenu, Switch, Tooltip } from 'antd';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { baseURL, basePath } from '../../routes';
import Search from '../search/search';
import './menu.scss';


const mapStateToProps = state => ({
    ...state.ea,
});

const mapDispatchToProps = dispatch => ({
    setEaMode: (payload) => {
        return dispatch(setEaMode(payload));
    },
    setZoom: (payload) => {
        return dispatch(setZoom(payload));
    }
})


const Menu = (props) => {
    const { setEaMode, eaMode, setZoom, zoom } = props;
    const [isMobileMenu, setIsMobileMenu] = useState(false);
    const [isSubMenuSectionOpen, setIsSubMenuSectionOpen] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState('');
    const [activeSubMenuItem, setActiveSubMenuItem] = useState(1);
    const location = useLocation();
    const [isEaInfoOpen, setIsEaInfoOpen] = useState(false);
    let navigate = useNavigate();

    const [menuItems, setMenuItems] = useState([
        {
            label: 'About Us',
            key: 'about',
            subItems: [
                {
                    label: 'Company Overview',
                    id: 1,
                    children: [
                        {
                            label: 'About Us',
                            key: `${baseURL}/company_overview`,
                        },
                        {
                            label: 'Leadership Team & Careers',
                            key: `${baseURL}/leadership_team`,
                        },
                        {
                            label: 'Diversity & Inclusion',
                            key: `${baseURL}/diversity_and_inclusion`,
                        },
                        {
                            label: 'Compliance & Ethics',
                            key: `${baseURL}/compliance_and_ethics`,
                        },
                    ]
                },
                // {
                //     label: 'Diversity & Inclusion',
                //     id: 3,
                //     children: [
                //         {
                //             label: 'Enhanced Accessibility',
                //             key: `${baseURL}/enhanced_accessibility`,
                //         },
                //         {
                //             label: 'Visual Impaired Access',
                //             key: `${baseURL}/visual_impaired_access`,
                //         },
                //         {
                //             label: 'Biometric Cards',
                //             key: `${baseURL}/biometric_cards`,
                //         },
                //     ]
                // },
            ]
        },
        {
            label: 'ESG at BKF',
            key: `${baseURL}/esg`,
            subItems: [],
        },
        {
            label: 'Our Solutions',
            key: 'solutions',
            subItems: [
                {
                    label: 'BluSys Core Solution',
                    id: 1,
                    children: [
                        {
                            label: 'Core Solution',
                            key: `${baseURL}/core_solutions`,
                        },
                        {
                            label: 'BluSys Pilot Integration Program',
                            key: `${baseURL}/pilot_integration_program`,
                        },
                    ]
                },
                // {
                //     label: 'Regulatory Umbrella Program',
                //     id: 2,
                //     children: [
                //         {
                //             label: 'Overview',
                //             key: `${baseURL}/regulatory_umbrella_program_overview`,
                //         },
                //         {
                //             label: 'Integrated Compliance Solutions',
                //             key: `${baseURL}/integrated_compliance_solutions`,
                //         },
                //         {
                //             label: 'FAQ',
                //             key: `${baseURL}/regulatory_umbrella_program_faq`,
                //         },
                //     ]
                // },
                {
                    label: 'Bespoke Financial Services',
                    id: 3,
                    children: [
                        {
                            label: 'Our Services',
                            key: `${baseURL}/customised_modular_services`,
                        },
                        {
                            label: 'White Label Biometric Solution',
                            key: `${baseURL}/white_label_consumer_app`,
                        },
                        {
                            label: 'Risk Assessment & Credit Scoring',
                            key: `${baseURL}/risk_assessment_and_credit_scoring`,
                        },
                        {
                            label: 'Underwriting & Loan Origination',
                            key: `${baseURL}/underwriting&loan_origination`,
                        },
                        {
                            label: 'Payment Processing',
                            key: `${baseURL}/payment_processing`,
                        },
                        {
                            label: 'Customer Monitoring',
                            key: `${baseURL}/custmomer_monitoring`,
                        },
                        {
                            label: 'Debt Collection',
                            key: `${baseURL}/debt_collection`,
                        },
                        {
                            label: 'Data Security & Privacy',
                            key: `${baseURL}/data_security&privacy`,
                        },
                        {
                            label: 'Marketing & Customer Acquisition',
                            key: `${baseURL}/marketing&customer_acquisition`,
                        },
                        {
                            label: 'Financial Management',
                            key: `${baseURL}/financial_management`,
                        },
                        {
                            label: 'Product Development & Market Analysis',
                            key: `${baseURL}/product_development&market_analysis`,
                        },
                        {
                            label: 'Card Issuing & Fulfilment',
                            key: `${baseURL}/card_issuing&fulfilment`,
                        },
                        {
                            label: 'Loyalty & Rewards Program',
                            key: `${baseURL}/loyalty&rewards_program`,
                        },
                    ]
                }
            ]
        },

        {
            label: 'Tailored Services',
            key: 'tailored',
            subItems: [
                {
                    label: 'Tailored Services',
                    id: 1,
                    children: [
                        {
                            label: 'Overview',
                            key: `${baseURL}/tailored_services_overview`,
                        },
                        {
                            label: 'For Banks',
                            key: `${baseURL}/tailored_services_banks`,
                        },
                        {
                            label: "For Building Society's",
                            key: `${baseURL}/strategic_technology_integration_for_building_societies`,
                        },
                        {
                            label: 'For Credit Unions',
                            key: `${baseURL}/growth_and_innovation_for_credit_unions`,
                        },
                        {
                            label: 'For FinTechs',
                            key: `${baseURL}/fin_techs_credit_solutions`,
                        },
                        {
                            label: 'For Underwriters',
                            key: `${baseURL}/liquidity_providers_credit_solutions`,
                        },
                        {
                            label: 'For Retailers',
                            key: `${baseURL}/tailored_services_retailers`,
                        },
                    ]
                },
            ]
        },
        {
            label: 'Knowledge Hub',
            key: `${baseURL}/knowledge_hub`,
            subItems: [],
        },
        {
            label: 'Contact Us',
            key: `${baseURL}/contact_us`,
            subItems: [],
        },
        {
            label: 'Search',
            key: 'search',
            subItems: [],
        }
    ])

    const findParentAndSubItem = (childKey, items) => {
        for (const item of items) {
            if (item.subItems) {
                for (const subItem of item.subItems) {
                    if (subItem.children) {
                        const index = subItem.children.findIndex(childItem => childItem.key === childKey);
                        if (index !== -1) {
                            return { parentKey: item.key, subItemIndex: subItem.id };
                        }
                    }
                }
            }
        }

        return { parentKey: null, subItemIndex: null };
    };

    useEffect(() => {
        const { parentKey, subItemIndex } = findParentAndSubItem(location.pathname, menuItems);
        if (parentKey !== null && subItemIndex !== null) {
            // if (!isSubMenuSectionOpen && activeMenuItem != parentKey) {
            // if (activeMenuItem != parentKey) {
            // setActiveMenuItem(parentKey);
            // }

            if (activeMenuItem === parentKey && subItemIndex) {
                setActiveSubMenuItem(subItemIndex);
            } else {
                setActiveSubMenuItem(1);
            }
        }

        if (location.pathname.includes('knowledge_hub') && !isSubMenuSectionOpen) {
            let knowledgeHubItem = menuItems.find(x => x.label === 'Knowledge Hub')
            setActiveMenuItem(knowledgeHubItem.key)
        }

        if (location.pathname.includes('esg') && !isSubMenuSectionOpen) {
            let esgItem = menuItems.find(x => x.label === 'ESG at BKF')
            setActiveMenuItem(esgItem.key)
        }
        if (location.pathname.includes('contact_us') && !isSubMenuSectionOpen) {
            let contactUsItem = menuItems.find(x => x.label === 'Contact Us')
            setActiveMenuItem(contactUsItem.key)
        }

    }, [location.pathname, menuItems, isSubMenuSectionOpen, activeMenuItem]);

    const showCorrectActiveMenuItem = () => {
        if (location.pathname === '/') {
            setActiveMenuItem('');
        }
        const { parentKey, subItemIndex } = findParentAndSubItem(location.pathname, menuItems);
        if (parentKey !== null && subItemIndex !== null) {
            if (activeMenuItem != parentKey) {
                setActiveMenuItem(parentKey);
            }
        }
    }

    useEffect(() => {
        if (location.pathname === '/') {
            setActiveMenuItem('');
        }
        showCorrectActiveMenuItem()

    }, [location.pathname])


    useEffect(() => {
        const updateMobileMenuState = () => {
            setIsMobileMenu(window.innerWidth <= 640);
        };

        updateMobileMenuState();

        const handleResize = () => {
            updateMobileMenuState();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!isMobileMenu) {
            window.addEventListener('scroll', isSticky);
            return () => {
                window.removeEventListener('scroll', isSticky);
            };
        }
    }, [isMobileMenu]);

    const isSticky = (e) => {
        const header = document.querySelector('.menuWrapper');
        const scrollTop = window.scrollY;
        scrollTop >= 100 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    const onClick = (e) => {
        // if (activeMenuItem === e.key) {
        // setIsSubMenuSectionOpen(!isSubMenuSectionOpen);
        // } else {
        setActiveSubMenuItem(1);
        setActiveMenuItem(e.key);

        if (e.key === "/knowledge_hub" || e.key === "/esg" || e.key === "/contact_us") {
            setIsSubMenuSectionOpen(false);
        }
        else {
            setIsSubMenuSectionOpen(true);
        }
    };


    // RENDER SUBSUBMENU ITEMS
    const showSubMenuItems = useCallback(() => {
        console.log("show submenu");
        let activeMenuItemObject = menuItems.find(x => x.key === activeMenuItem);
        let activeSubMenuItemObject = activeMenuItemObject.subItems.find(x => x.id === activeSubMenuItem);

        console.log('activeMenuItemObject ', activeMenuItemObject);

        if (activeMenuItemObject.key === 'search') {
            return (
                <Search closeSearch={() => setIsSubMenuSectionOpen(!isSubMenuSectionOpen)} />
            )
        }
        else {
            if (!activeSubMenuItemObject) return
            return (
                <div className='w-fit py-4'>
                    <div className='subMenu__items'>
                        {activeSubMenuItemObject.children.map(x => (
                            <NavLink
                                onClick={() => setIsSubMenuSectionOpen(false)}
                                key={x.key}
                                to={{ pathname: x.key.split('#')[0], hash: x.key.split('#')[1] || '' }}
                                className={({ isActive }) =>
                                    isActive ? "subMenu__childItem--active" : "subMenu__childItem"
                                }
                            >
                                {x.label}
                            </NavLink>
                        ))}
                    </div>
                </div>
            );
        }
    }, [activeMenuItem, activeSubMenuItem]);


    return (
        isMobileMenu ? (
            <MobileMenu />
        ) : (
            <div className='menuWrapper'>
                {isEaInfoOpen &&
                    <div className='eaWrapper'>
                        <div className='ea container flex justify-between'>
                            <div className='flex items-center gap-1 cursor-pointer'>
                                <p className='mr-3'>Zoom:</p>
                                <div className={zoom === '100%' ? "ea__zoomItem--active" : "ea__zoomItem"} onClick={() => setZoom({ zoom: '100%' })}>100%</div>
                                <div className={zoom === '116%' ? "ea__zoomItem--active" : "ea__zoomItem"} onClick={() => setZoom({ zoom: '116%' })}>120%</div>
                                <div className={zoom === '140%' ? "ea__zoomItem--active" : "ea__zoomItem"} onClick={() => setZoom({ zoom: '140%' })}>150%</div>
                                <div className={zoom === '180%' ? "ea__zoomItem--active" : "ea__zoomItem"} onClick={() => setZoom({ zoom: '180%' })}>200%</div>
                            </div>
                            <div>
                                <div className='py-1 px-5 cursor-pointer flex gap-5 items-center' onClick={() => setEaMode({ eaMode: !eaMode })}>
                                    <div className='flex items-center gap-2'>Text-to-speech
                                        <img className='w-5' src={`${basePath}images/ico/voiceAssistant.svg`} alt="voiceAssistant icon" />
                                    </div>
                                    <Switch checked={eaMode} onChange={() => setEaMode({ eaMode: !eaMode })} />
                                </div>
                            </div>

                            <div onClick={() => setIsEaInfoOpen(false)} className='px-5 cursor-pointer hover:shadow-lg transition flex items-center gap-3 border border-separate rounded-lg'>Close
                                <img className='w-3.5' src={`${basePath}images/ico/close.svg`} alt="voiceAssistant icon" />
                            </div>
                        </div>
                    </div>
                }
                <div className='menu'>
                    <div className='container flex justify-between items-center'>

                        <div className='flex w-full'>

                            <NavLink to={{ pathname: baseURL }} aria-current="page" className='menu__logoWrapper'><img className='cursor-pointer' alt="logo" src={`${basePath}images/logo-bold.svg`} /></NavLink>

                            <div className="menu__items">
                                <AntdMenu
                                    // onClick={onClick}
                                    selectedKeys={[activeMenuItem]}
                                    mode="horizontal"
                                    expandIcon='./images/ico/downArrow.svg'
                                    items={menuItems.map(item => ({
                                        label: item.key === 'search' ? <div key={item.key} onMouseEnter={() => onClick(item)} onMouseOut={() => showCorrectActiveMenuItem()} className='flex gap-2 menu__searchMenuItem'>{item.label}

                                            <div className='w-5 cursor-pointer flex items-center'>
                                                <svg width="20" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.33464 0.666992C4.55197 0.666992 0.667969 4.55099 0.667969 9.33366C0.667969 14.1163 4.55197 18.0003 9.33464 18.0003C14.1173 18.0003 18.0013 14.1163 18.0013 9.33366C18.0013 4.55099 14.1173 0.666992 9.33464 0.666992ZM9.33464 2.00033C13.3826 2.00033 16.668 5.28566 16.668 9.33366C16.668 13.3817 13.3826 16.667 9.33464 16.667C5.28664 16.667 2.0013 13.3817 2.0013 9.33366C2.0013 5.28566 5.28664 2.00033 9.33464 2.00033Z" fill="#0E2A38" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23.1396 22.1946L15.4609 14.5173C15.2009 14.2573 14.7782 14.2573 14.5182 14.5173C14.2582 14.7773 14.2582 15.1999 14.5182 15.4599L22.1956 23.1386C22.4569 23.3986 22.8782 23.3986 23.1396 23.1386C23.3996 22.8773 23.3996 22.4559 23.1396 22.1946Z" fill="#0E2A38" />
                                                </svg>
                                            </div>

                                        </div> :
                                            <div
                                                onClick={() => {
                                                    if (item.key === "/knowledge_hub") {
                                                        navigate(`${baseURL}/knowledge_hub`);
                                                    } else if (item.key === "/esg") {
                                                        navigate(`${baseURL}/esg`);
                                                    } else if (item.key === "/contact_us") {
                                                        navigate(`${baseURL}/contact_us`);
                                                    }
                                                }}
                                                onMouseEnter={() => onClick(item)}
                                                onMouseOut={() => {
                                                    if (item.key === "/knowledge_hub" || item.key === "/esg" || item.key === "/contact_us") {
                                                        showCorrectActiveMenuItem();
                                                    }
                                                }}
                                            >
                                                {item.label}
                                            </div>,


                                        key: item.key
                                    }))} />
                            </div>
                        </div>
                        <div tabIndex={0} className='flex gap-2 items-center flex-none menu__eaBtnWrapper' onKeyDown={(e) => e.key === 'Enter' && setIsEaInfoOpen(!isEaInfoOpen)} onClick={() => setIsEaInfoOpen(!isEaInfoOpen)}>
                            <p className='text-right'>Enhanced Accessibility</p>
                            <img src={`${basePath}images/ico/ea.svg`} className='menu__eaBtn' alt="ea" />
                        </div>
                    </div>
                </div>
                {activeMenuItem && isSubMenuSectionOpen &&
                    <div className='subMenuWrapper'>
                        <div className='subMenuOverlay fadeInFast' onMouseEnter={() => { setIsSubMenuSectionOpen(false); showCorrectActiveMenuItem() }} onClick={() => setIsSubMenuSectionOpen(false)}></div>
                        <div className='subMenu'>
                            <div className='container flex'>
                                <div className='subMenu__itemsMain'>
                                    {menuItems.find(x => x.key === activeMenuItem).subItems.map(menuItem => {
                                        return (
                                            <div key={menuItem.id} tabIndex={menuItem.id} role="button"
                                                className={activeSubMenuItem === menuItem.id ? "subMenu__item--active" : "subMenu__item"}
                                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); setActiveSubMenuItem(menuItem.id); }}
                                                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); e.stopPropagation(); setActiveSubMenuItem(menuItem.id) }}
                                            >
                                                {menuItem.label}
                                            </div>
                                        )
                                    })}
                                </div>

                                {showSubMenuItems()}

                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu);